import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container" style={{marginBottom:10}}>
        <div className="section-title fade-left">
          <h2 style={{color:'#333'}}>Our Services</h2>
          <p style={{color:'#444'}}>
          Delivering comprehensive testing and quality assurance for optimal solar performance.
          </p>
        </div>
        <div className="row" style={{ justifyContent: 'center' }}>
          {props.data ? (
            <>
              {props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 mb-4 d-flex align-items-start">
                  <img src={d.icon} className="fade-left" style={{ height: 150,width:150,borderRadius:'50%' }} alt={d.name} />
                  {/* <FontAwesomeIcon className="icons fade-up" icon={d.icon} size="sm" style={{height:50}}/> */}
                  {/* <i className={d.icon}></i> */}
                  <div className="service-desc fade-left">
                    <h3>{d.name}</h3>
                    <p style={{color:'#444'}}>{d.text}</p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};
