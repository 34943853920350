import React from 'react';

const logos = [
  { src: require('../assets/images/companies/anchor.png'), alt: 'Company 1' },
  { src: require('../assets/images/companies/axitec.png'), alt: 'Company 2' },
  { src: require('../assets/images/companies/engie.png'), alt: 'Company 3' },
  { src: require('../assets/images/companies/herofuture.jpg'), alt: 'Company 4' },
  { src: require('../assets/images/companies/jinko.png'), alt: 'Company 5' },
  { src: require('../assets/images/companies/jk lakhsmi.png'), alt: 'Company 6' },
  { src: require('../assets/images/companies/mahindra.jpg'), alt: 'Company 7' },
  { src: require('../assets/images/companies/qcell.png'), alt: 'Company 8' },
  { src: require('../assets/images/companies/teqo.png'), alt: 'Company 9' },
  { src: require('../assets/images/companies/trina soalr.png'), alt: 'Company 10' },
];

export const Companies = () => {
  return (
    <div className="logo-scroll-container" style={{ marginBottom: 50 }}>
      <h2 className="header text-center" style={{ marginBottom: 30 }}>
        Trusted by 10+ amazing companies
      </h2>
      <div className="logo-scroll">
        {/* Render the logos */}
        {logos.map((logo, index) => (
          <img
            key={index}
            src={logo.src}
            alt={logo.alt}
            className="logo"
          />
        ))}
        {/* Duplicate the logos for seamless scrolling */}
        {logos.map((logo, index) => (
          <img
            key={`duplicate-${index}`}
            src={logo.src}
            alt={logo.alt}
            className="logo"
          />
        ))}
      </div>
    </div>
  );
};
