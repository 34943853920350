import { Image } from "./image";
import React from "react";
import { faLightbulb, faTasks, faWaveSquare, faThermometerHalf, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Gallery = (props) => {
  // {
  //   icon: 'fas fa-users', // Use Font Awesome icons
  //   value: '60+',
  //   description: 'Full-time Inspectors/ Auditors/ Engineers',
  // },
  const data = [
    {
      icon: 'fas fa-solar-panel', // Use Font Awesome icons
      value: '>30MW',
      description: 'EL SOLAR MODULE TESTING',
    },
    {
      icon: 'fa-solid fa-chart-line-down', // Use Font Awesome icons
      value: '>1 lac',
      description: 'IV TESTING BY SOLMETRIC IV TRACER',
    },
    {
      icon: 'fas fa-users-cog', // Use Font Awesome icons
      value: '>15',
      description: 'CLIENTS',
    },
    {
      icon: 'fas fa-chart-line', // Use Font Awesome icons
      value: '>1 LAC',
      description: 'THERMAL CAPTURE IMAGE',
    },
    {
      icon: 'fas fa-tasks', // Use Font Awesome icons
      value: '>15/year',
      description: 'Quality Assessment Projects',
    },
  ];
  return (
    <div id="projects" className="text-center" style={{ marginBottom: 50 }}>
      <div className="container">
        <div className="section-title">
          <h2 className="fade-left">Projects</h2>
          <p className="fade-left">
            Established in 2018 by a team of experienced professionals, JSR Renewable Consultants was founded with a passion for advancing renewable energy and ensuring the highest quality standards in the solar industry. We understand that the journey toward sustainability demands innovative, client-focused, and industry-relevant solutions. Through our expert testing, inspection, and advisory services, we empower our clients to make informed decisions that enhance solar energy efficiency and reliability. We frequently provide our clients with the data, tools, and insights they need to effectively contribute to a more sustainable future.
          </p>
        </div>

        <div className="row fade-up">
          {data.map((item, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card md-">
                <div className="card-body">
                  <div className="icon-container">
                    {index == 1 ? <FontAwesomeIcon className="icons" icon={faChartLine} style={{ height: 50, color: '#007bff' }} /> : index == 3 ? <FontAwesomeIcon className="icons" icon={faThermometerHalf} style={{ height: 50, color: '#007bff' }} /> : <i className={item.icon}></i>}

                    <div className="text-content">
                      <h2 className="module-value">{item.value}</h2>
                      <p style={{ textTransform: 'capitalize' }}>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div> */}
      </div>
    </div>
  );
};
