import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import {Companies} from "./components/compnaies";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import "./assets/css/carousel.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { faLightbulb, faTasks, faWaveSquare,faThermometerHalf } from '@fortawesome/free-solid-svg-icons';
import elimg from "../src/assets/images/el_icon.jpeg";
import thermalimg from "../src/assets/images/thermal_icon (2).jpeg";
import ivimg from "../src/assets/images/solar.jpeg";
import processimg from "../src/assets/images/process_icon.jpeg";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

var Servicesdata = [
  {
    // "icon": faLightbulb,
    "icon": ivimg,
    "name": "Electroluminescence (EL) Testing",
    "text": "Our EL testing identifies microcracks, hotspots, and defects in solar panels, ensuring reliability and optimal performance before installation."
  },
  {
    // "icon": faThermometerHalf,
    "icon": thermalimg,
    "name": "Thermal Imaging",
    "text": "We conduct thermal imaging to detect temperature variations in solar panels, helping to prevent overheating and improve system efficiency.."
  },
  {
    // "icon": faWaveSquare,
    "icon": processimg,
    "name": "IV Curve Testing",
    "text": "IV testing evaluates the electrical performance of photovoltaic modules, ensuring that they meet industry standards and perform at peak efficiency."
  },
  {
    // "icon": faTasks,
    "icon": elimg,
    "name": "In-Process and Pre-Dispatch Processing",
    "text": "We ensure product quality through rigorous in-process inspections and pre-dispatch checks, guaranteeing that panels meet specifications before shipment.."
  }
];

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Services data={Servicesdata} />
      <Gallery data={landingPageData.Gallery} />
      <Companies />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
