import React from "react";

export const Contact = (props) => {
   // Example coordinates (latitude, longitude)
   const latitude = 21.193196;
   const longitude = 72.787500; // New York City coordinates
 
   // Google Maps URL for the location
   const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row" style={{ justifyContent: 'start' }}>
              <div className="section-title fade-left">
                <h2>Contact Info</h2>
              </div>
              <div className="row contact-info" style={{justifyContent:'start'}}>
                <div className="col-md-3 col-xs-12">
                  <div className="contact-item fade-left">
                    <a href="tel:+91 9737596297" style={{color:'#fff'}}>
                      <span>
                        <i className="fa fa-phone"></i> Phone
                      </span>{" "}
                      {props.data ? props.data.phone : "loading"}
                    </a>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="contact-item fade-left">
                    <a href="mailto:info@jsr-renewables.com" style={{color:'#fff'}}>
                      <span>
                        <i className="fa fa-envelope-o"></i> Email
                      </span>{" "}
                      {props.data ? props.data.email : "loading"}
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="contact-item fade-left">
                    <a href={googleMapsUrl} style={{color:'#fff'}}>
                      <span>
                        <i className="fa fa-map-marker"></i> Address
                      </span>
                      {props.data ? props.data.address : "loading"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a>
          </p>
        </div>
      </div> */}
    </div>
  );
};
