import React, { useEffect, useState } from "react";
import slider from "../assets/images/slider.png"; // Ensure this path is correct
import slider2 from "../assets/images/slider2.jpg"; // Ensure this path is correct
import slider3 from "../assets/images/slider3.jpg"; // Ensure this path is correct
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import styles
import { Carousel } from "react-responsive-carousel";

export const Header = () => {
  const images = [
    {
      src: slider,
      alt: "Slide 1",
      caption: "Manufacturing End",
      description:
        "Ensuring top-notch quality from start to finish with rigorous pre- and post-production inspections, safeguarding the efficiency and durability of solar panels.",
    },
    {
      src: slider3,
      alt: "Slide 2",
      caption: "Thermal Imaging",
      description:
        "Optimizing energy output with precise thermal imaging, preventing overheating and improving the efficiency of solar panels.",
    },
    {
      src: slider2,
      alt: "Slide 3",
      caption: "Electroluminescence Testing",
      description:
        "Detecting hidden defects and microcracks through advanced EL testing, ensuring long-lasting solar panel performance.",
    },
  ];

  // Create a duplicated array of images for seamless looping
   const numberOfDuplicates = 100; // Set the number of times to duplicate the images
  const duplicatedImages = Array(numberOfDuplicates).fill(images).flat();

  // State to track the current index of the displayed image
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [intervalId, setIntervalId] = useState(null);

  // Handle the slide change manually
  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % duplicatedImages.length);
  };

  useEffect(() => {
    if (isPlaying) {
      const id = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % duplicatedImages.length); // Loop through duplicated images
      }, 3000); // Adjust the interval timing as needed
      setIntervalId(id); // Save the interval ID for cleanup
    } else {
      clearInterval(intervalId); // Clear the interval if paused
    }

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [isPlaying]);

  const handleCarouselClick = () => {
    setIsPlaying((prev) => !prev); // Toggle play/pause state
  };

  return (
    <header id="header">
      <Carousel
        showThumbs={false}
        infiniteLoop={true} // Enable infinite looping
        autoPlay={isPlaying} // Enable auto play
        selectedItem={currentIndex}
        onChange={setCurrentIndex} // Update current index on slide change
        transitionTime={1000} // Transition time for smoothness
        interval={3000} // Duration of each slide before switching
        stopOnHover={true} // Stop auto-scrolling on hover
        showStatus={false} // Hide slide status
        swipeable={true} // Enable swipe support
        emulateTouch={true} // Enable touch support
        dynamicHeight={true} // Adjust height based on content
        showIndicators={false}
        onClickItem={handleCarouselClick}
      >
        {duplicatedImages.map((image, index) => (
          <div key={index} style={{ position: "relative" }}>
            <img
              src={image.src}
              alt={image.alt}
              className="d-block w-100"
              style={{ objectFit: "cover" }} // Maintain aspect ratio
              onError={(e) => {
                e.target.src = "path/to/fallback-image.jpg"; // Path to a fallback image
              }}
            />
            <div
              className="carousel-caption responsive-class"
              style={{
                position: "absolute",
                bottom: 20,
                left: 20,
                zIndex: 2,
                textAlign: "left",
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.2)", // Optional: Background for better readability
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <h1 className="responsive-caption">{image.caption}</h1>
              <p className="responsive-description">{image.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </header>
  );
};
